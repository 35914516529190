<template>
    <section class="zc-reservas-main">
        <div class="row mb-4">
            <div class="col-auto" />
            <div class="col text-black f-20 f-500">
                Reservas por verificar pago
            </div>
            <div class="col-auto" style="opacity:0.5;pointer-events:none;">
                <div class="button-like-router justify-content-around d-flex">
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="text-86">
                            Ver en lista
                        </div>
                        <router-link :to="{ name: 'zonas.verificar-pago.listado' }" class="d-flex d-middle-center">
                            <i class="icon-hamburguer cr-pointer" />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="text-86">
                            Ver en calendario
                        </div>
                        <router-link :to="{ name: 'zonas.vericar-pago.calendario' }" class="d-flex d-middle-center">
                            <i class="icon-calendar cr-pointer" />
                        </router-link>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            buscar  : ''
        }
    }
}
</script>
<style lang="scss" scoped>
.button-like-router{
    height: 32px;
    width: 64px;
    border: 1px solid #CFD7DF;
    border-radius: 4px;
    a{
        height: 32px;
        width: 32px;
        border-radius: 4px;
        color: #000;
        &:first-child{
            border-radius: 4px 0px 0px 4px;
        }
        &:last-child{
            border-radius: 0px 4px 4px 0px;
        }
        &.router-link-exact-active.router-link-active{
            background: var(--color-general);
            color: #fff;
        }
    }
}
</style>